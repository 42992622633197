import React, { useContext, useState } from 'react';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from '../Dropdown';
import { HStack } from '../Stack';
import { useMediaQuery } from '../../hooks';
import { theme } from '../../shared/theme';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
    MobileSearchText,
    Section,
    SectionTitle,
    StyledSearchBar,
    MobileSearchIconButton,
    LocationSection,
    SearchButton,
	MobileStyledSearchBar,
	ClearButton
} from './styles';
import AutoCompletionTextField from '../AutoCompletionTextField';
import { MainSearchOption, MainSearchType } from '../../types/internal';
import { MainQueryType } from '../../types/external';
import { configName } from '../../shared/globals';
import {
    defaultQuery,
    mainSearchTypeOptions,
    mapLocationSearchOption,
} from '../../shared/data';
import { autocompleteFilterSearchApiUrl, locationBoxSearchApiUrl } from '../../shared/globals';
import axios from 'axios';
import { QueryContext, FilterContext } from '../../App';
import { constructSearchQuery } from '../../services/api';
import { doesWidgetHideThisSearchType, widgetApplyWidgetToQuery, widgetGetPluralLabelForText } from '../../utils/SiteWidgetizer';
import { getCurrentQuery, setCurrentQuery, updateCurrentQuery } from '../../utils/SearchHelper';

interface Props {
    onSearchButtonClick: () => void;
}

export default function SearchBar(props: Props) {
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });
	const queryContext = useContext(QueryContext);
	const [wasURLInitialized, setWasURLInitialized] = useState(false);
	const filterContext = useContext(FilterContext);

	var filteredSearchTypeOptions = mainSearchTypeOptions.filter(m => !doesWidgetHideThisSearchType(m.key));
	if (configName == "env.production") {
		filteredSearchTypeOptions = filteredSearchTypeOptions.filter(m => m.key != 'job'
			&& m.key != 'industry'
			&& m.key != 'pathwayset'
	/*		&& m.key != 'progressionmodel'*/
			//&& m.key != 'task'
			//&& m.key != 'workrole'
		);
	} else if (configName == "env.flstaging") {
		filteredSearchTypeOptions = filteredSearchTypeOptions.filter(m => m.key == 'credential'
			|| m.key == 'organization'
			|| m.key == 'collection'

		);
	} else if (configName == "env.texas") {
		filteredSearchTypeOptions = filteredSearchTypeOptions.filter(m => m.key == 'credential'
			|| m.key == 'organization'
			|| m.key == 'learningopportunity'
			|| m.key == 'collection'

		);
	}
	var availableOptions = filteredSearchTypeOptions.map(m => m.key);
	if (!wasURLInitialized) {
		var targetSearchType = new URLSearchParams(window.location.search).get("searchType") || "";
		if (targetSearchType.length > 0) {
			queryContext.query.SearchType = (availableOptions.filter(m => m == targetSearchType)[0] || availableOptions[0]) as MainSearchType;
		}
		setWasURLInitialized(true);
	}
	
    function getDefaultDropboxIndex(type: 'main' | 'map') {
		const searchType = queryContext.query.SearchType || 'credential';

		let defaultIndex = filteredSearchTypeOptions.findIndex((option) => {
            return option.key === searchType;
        });
        if (type === 'map') {
            const mapPositionType =
                queryContext.query.MapFilter?.PositionType || 'positionType:In';

            defaultIndex = mapLocationSearchOption.findIndex((option) => {
                return option.key === mapPositionType;
            });
        }
        return defaultIndex;
    }

    function getDefaultMainSearchType() {
        return getDefaultDropboxIndex('main');
    }

    function getDefaultMapLocationPositionType() {
        return getDefaultDropboxIndex('map');
    }

    function onSearchButtonClick(e: any) {
        props.onSearchButtonClick();
    }

	function onSelectMainSearchOption(option: any) {
		updateCurrentQuery((query: any) => {
			query.Keywords = option.Label;
		});
		/* */

        queryContext.updateQuery({
            ...queryContext.query,
            Keywords: option.Label,
        });
    }

	function onBroadTypeOptionSelect(option: MainSearchOption) {
		clearSearch();
		updateCurrentQuery((query: any) => {
			query.SearchType = option.key;
		});
		(window as any).IsOriginalSearchType = false;
		/* */

        queryContext.updateQuery({
            ...defaultQuery,
            SearchType: option.key,
        });
    }

	async function onMainTextSearchChange(value: string) {
		updateCurrentQuery((query: any) => {
			query.Keywords = value;
			query.Force = true; //Force the query to run when the user types something, even if it is identical to the previous time it ran
		});
		/* */

        const url = `${autocompleteFilterSearchApiUrl}`;
        const query = {
            SearchType: queryContext.query.SearchType,
            FilterURI: 'mainsearch',
            FilterItemURI: 'interfaceType:Text',
            Text: value,
        };
        queryContext.updateQuery({
            ...queryContext.query,
            Keywords: value,
		});
		const autocompleteQuery = {
			...constructSearchQuery(queryContext.query, filterContext[queryContext.query.SearchType]),
			Keywords: value,
			AutocompleteContext: 'mainsearch'
		};
		widgetApplyWidgetToQuery(autocompleteQuery, queryContext.query.SearchType);
        try {
			const res = await axios.post(url, autocompleteQuery);
            return res.data.Successful ? res.data.Result.Items || [] : [];
        } catch (error) {
            return [];
        }
    }

	function onLocationSearchOptionSelect(value: any) {
		updateCurrentQuery((query: any) => {
			query.MapFilter = query.MapFilter || {};
			query.MapFilter.PositionType = value.key;
		});
		/* */

        queryContext.updateQuery({
            ...queryContext.query,
            MapFilter: {
                ...queryContext.query.MapFilter,
                PositionType: value.key,
            },
        });
    }

	async function onLocationTextSearchChange(value: string) {
		const url = `${locationBoxSearchApiUrl}`;
		if (value && value.length > 0) {
			const query = {
				Text: value
			};
			try {
				const res = await axios.post(url, query);
				return res.data.Successful ? res.data.Result || [] : [];
			} catch (error) {
				return [];
			}
		}
		else {
			queryContext.query.MapFilter = {};
			queryContext.updateQuery({
				...queryContext.query
			});
			return [];
		}
    }

	function onLocationTextSearchOptionSelect(option: any) {
		updateCurrentQuery((query: any) => {
			query.MapFilter = {
				...option,
				PositionType: query.MapFilter?.PositionType,
				Keywords: createFancyLocationLabel(option)
			}
		});
		/*  */

        queryContext.updateQuery({
            ...queryContext.query,
            MapFilter: {
				...option,
				PositionType: queryContext.query.MapFilter?.PositionType,
				Keywords: createFancyLocationLabel(option)
            },
        });
	}

	function locationTextSearchAddItemHandler(searchText: any, setSearchText: any, suggestions: any[] | undefined, onSelect: any) { //Force a selection. Function names are a little misleading here.
		if (queryContext.query.MapFilter?.Keywords != searchText) {
			if (suggestions && suggestions.length > 0 && onSelect) {
				onSelect(suggestions[0]);
			}
			else {
				setSearchText("");
				queryContext.query.MapFilter = {};
				queryContext.updateQuery({
					...queryContext.query
				});
			}
		}
	}

	function createFancyLocationLabel(option: any) {
		return option.Label + (option.Label == option.Region || option.Region == option.Country ? "" : ", " + option.Region) + " (" + option.Country + ")" || '';
	}

	function clearSearch() {
		var query = getCurrentQuery();
		setCurrentQuery({
			SearchType: query.SearchType,
			PageSize: query.PageSize,
			SkipPages: 0
		});
		updateCurrentQuery();
		/* */

		var widgetFilters = queryContext.query.MainFilters.filter(m => m?.Id?.toString().includes("widget"));
		queryContext.updateQuery({
			...queryContext.query,
			Keywords: "",
			MapFilter: { PositionType: "positionType:In" },
			MainFilters: widgetFilters
		});
	}

	/*
	function clearSearch() {
		var widgetFilterKeys = Object.keys(queryContext.query.MainFilters).filter(m => m.includes("widget"));
		var widgetFilters = {} as any;
		widgetFilterKeys.forEach(m => {
			widgetFilters[m] = queryContext.query.MainFilters[m];
		});
		queryContext.updateQuery({
			...queryContext.query,
			Keywords: "",
			MapFilter: { PositionType: "positionType:In" },
			MainFilters: widgetFilters
		});
	}
	*/


    if (isMobile) {
        return (
            <MobileStyledSearchBar spacing="5px">
                <HStack spacing="5px" align="center">
                    <MobileSearchText>Search</MobileSearchText>
                    <Section flex={1}>
						<Dropdown
							ariaLabel="Search Type"
							defaultOptionIndex={getDefaultMainSearchType()}
							options={filteredSearchTypeOptions}
							getOptionLabel={(option: MainSearchOption) => { return widgetGetPluralLabelForText(option.value); }}
                            onChange={onBroadTypeOptionSelect}
						/>
                    </Section>
                </HStack>
                <HStack spacing="5px" align="center">
                    <MobileSearchText>for</MobileSearchText>
                    <HStack spacing="5px">
                        <AutoCompletionTextField
							placeholder="topics or keywords ..."
							value={queryContext.query.Keywords || ''}
							onChange={onMainTextSearchChange}
							onSelect={onSelectMainSearchOption}
							getOptionLabel={(option) => option.Label || ''}
							addItemHandler={() => { onMainTextSearchChange(queryContext.query.Keywords || ''); }}
                        />
                        <MobileSearchIconButton
                            buttonType="tertiary"
                            onClick={onSearchButtonClick}>
                            <FontAwesomeIcon
                                icon={faSearch}
                                size="lg"
                                color="white"
                            />
                        </MobileSearchIconButton>
                    </HStack>
                </HStack>
                <HStack spacing="5px">
					<Dropdown
						ariaLabel="Location Type"
                        defaultOptionIndex={getDefaultMapLocationPositionType()}
                        options={mapLocationSearchOption}
                        getOptionLabel={(option) => option.value || ''}
						onChange={onLocationSearchOptionSelect}
                    />
                    <AutoCompletionTextField
                        value={queryContext.query.MapFilter?.Keywords || ''}
                        placeholder="All Locations"
						onChange={onLocationTextSearchChange}
						onSelect={onLocationTextSearchOptionSelect}
						getOptionLabel={(option) => createFancyLocationLabel(option)}
                    />
                </HStack>
            </MobileStyledSearchBar>
        );
    }
    return (
        <StyledSearchBar spacing="4px">
            <Section flex={3} minWidth={180}>
                <SectionTitle>Search</SectionTitle>
				<Dropdown
					ariaLabel="Search Type"
					defaultOptionIndex={getDefaultMainSearchType()}
					options={filteredSearchTypeOptions}
					getOptionLabel={(option: MainSearchOption) => widgetGetPluralLabelForText(option.value)}
                    onChange={onBroadTypeOptionSelect}
                />
            </Section>
            <Section flex={5} minWidth={240}>
                <HStack justify="space-between" align="center">
                    <SectionTitle>for</SectionTitle>
                    {/* <FontAwesomeIcon icon={faQuestionCircle} size="lg" /> */}
                </HStack>
                <AutoCompletionTextField
					placeholder="Start typing to search ..."
					value={queryContext.query.Keywords || ''}
					onChange={onMainTextSearchChange}
					onSelect={onSelectMainSearchOption}
					getOptionLabel={(option) => option.Label || ''}
					addItemHandler={() => { onMainTextSearchChange(queryContext.query.Keywords || ''); }}
					fieldName={"MainKeywords"}
                />
            </Section>
            <LocationSection>
				<Dropdown
					ariaLabel="Location Type"
                    defaultOptionIndex={getDefaultMapLocationPositionType()}
                    options={mapLocationSearchOption}
                    getOptionLabel={(option) => option.value || ''}
                    onChange={onLocationSearchOptionSelect}
                />
            </LocationSection>
            <Section flex={3} minWidth={180}>
                <AutoCompletionTextField
					value={queryContext.query.MapFilter?.Keywords || ''}
					placeholder="All Locations"
					onChange={onLocationTextSearchChange}
					onSelect={onLocationTextSearchOptionSelect}
					getOptionLabel={(option) => createFancyLocationLabel(option)}
					addItemHandler={locationTextSearchAddItemHandler}
					fieldName={"MainKeywords"}
                />
            </Section>
            <SearchButton buttonType="tertiary" onClick={onSearchButtonClick}>
                Search
			</SearchButton>
			<ClearButton title="Clear Search Filters" buttonType="secondary" onClick={clearSearch}>X</ClearButton>
        </StyledSearchBar>
    );
}

