import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import transferValueIcon from '../../assets/images/icons/icon-transfer-value-green-blue-01.svg';
import pageIcon from '../../assets/images/UpdatedIcons/CE-Icon_17-Transfer-Intermediary_Blue-Green_High-Res.png';
import Banner from '../../components/Banner';
import { WhiteLink } from '../../components/Banner/styles';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
/*import ConditionProfileList from '../../components/DetailPage/ConditionProfile';*/
import ConditionProfileList, { getConditionProfileListButtonName } from './../../components/DetailPage/ConditionProfile';
import LinkObject from '../../components/DetailPage/LinkObject';
import OutlineList from '../../components/DetailPage/Outline';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import {
    Description,
    FullWidthWrapper,
    InnerWrapper,
    Label, Section,
    Wrapper
} from '../../components/DetailPage/styles';
import ValueProfileList from '../../components/DetailPage/ValueProfile';
import HasAnyFilled from '../../components/HasAnyFilled';
import LoadingOrError from '../../components/LoadingOrError';
import { ModalButtonAndWindow } from '../../components/Modal';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { VStack } from '../../components/Stack';
import { useGet, useMediaQuery } from '../../hooks';
import { apiUrl, legacySourceUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import { TransferIntermediary } from '../../types/external';
import { detailSectionProperties } from '../../utils';
import { CompetencyFrameworkList } from '../../components/DetailPage/CompetencyFramework';
import SearchPills from '../../components/SearchPills';
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";



interface Props {
    id: string;
}

export default function TransferIntermediaryPage(props: RouteComponentProps<Props>) {
    const id = props.match.params.id;
    const [
        pageDataIsLoading,
        pageDataHasError,
        getPageData,
    ] = useGet();
    const [pageModel, setPageData] = useState({} as TransferIntermediary);
    const isMobile = useMediaQuery({
        query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
    });

    useEffect(() => {
		getPageData(`${apiUrl}/transferintermediary/${id}`, (data: TransferIntermediary) => {
			setPageData(data);
        });
    }, [getPageData, id]);
	//

	function getSubTitleLink() {
		if ( pageModel.OwnedByLabel && pageModel.OwnedByLabel.Label) {
			return pageModel.OwnedByLabel.URL ?
				<WhiteLink href={pageModel.OwnedByLabel.URL}>{pageModel.OwnedByLabel.Label}</WhiteLink> :
				<>{pageModel.OwnedByLabel.Label}</>;
		}
		return null;
	}

	function getLegacyLink() {
		if ( pageModel.Meta_Id ) {
			const url = legacySourceUrl + "/" + pageModel.BroadType + "/" + pageModel.Meta_Id;
            const mystyle = {
                //color: "rgba(0,0,0,.01)",
                color: "transparent",
                //backgroundColor: "rgba(0,0,0,.01)",
                marginLeft: "10px"
            };
			return <>
				<WhiteLink href={url} target="legacy" style={mystyle} title="View in services application">Legacy</WhiteLink>
				</>
            
		}
		return null;
	}
	
	var typeLabel = widgetGetSingleLabelForText("Transfer Intermediary");

    return (
        <LoadingOrError
            isLoading={pageDataIsLoading}
            hasError={pageDataHasError}>
            
            <Banner
            icon={pageIcon}
            fallBackIcon={pageIcon}
            type={pageModel.CTDLTypeLabel}
            title={pageModel.Name}
            subTitle={getSubTitleLink()}
            content={
                <small>Last updated: {pageModel.Meta_LastUpdatedHeader} <span  >{getLegacyLink()}</span></small>
            } 
        />
            <Wrapper>
                <VStack spacing={isMobile ? '0px' : '20px'}>
                    <HasAnyFilled
                        data={pageModel}
                        keys={detailSectionProperties.transferIntermediaryAbout}>
                        <PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about this " + typeLabel}>
                            
                            {pageModel.Description && (
                                <PageSectionItem>
                                    <Description>
                                        {pageModel.Description}
                                    </Description>
                                </PageSectionItem>
                                )}
                            
                            
                            {pageModel.SubjectWebpage &&
                                <PageSectionItem>
                                    <LinkObject item={{ URL: pageModel.SubjectWebpage, Label: "View this " + typeLabel }} />
                                </PageSectionItem>
                                }
                            
                 
                            {/*{pageModel.IntermediaryFor &&*/}
                            {/*    <PageSectionItem>*/}
                            {/*        <Label>Intermediary For</Label>*/}
                            {/*        <SearchPills links={pageModel.Connections} />*/}
                            {/*    </PageSectionItem>*/}
                            {/*}*/}
                     

                                {pageModel.CreditValue &&
								    <PageSectionItem>
									    <Label>Credit Value</Label>
                                    <ValueProfileList items={pageModel.CreditValue} />
								    </PageSectionItem>
                                }
                    
                            <PageSectionItem>
                                <ModalButtonAndWindow
                                    buttonLabel={getConditionProfileListButtonName("Requires", pageModel.Requires)}
                                    hideCount={true}
                                    resourceTitle={pageModel.Name}
                                    items={pageModel.Requires}
                                    Wrapper={Section}
                                    Content={() => <ConditionProfileList items={pageModel.Requires} />}
                                />
                            </PageSectionItem>
                        </PageSection>
					</HasAnyFilled>
					{/*<AdditionalInformationPageSection item={pageModel} pageSectionDescription="Identifiers and other information for the Transfer Intermediary" />  */}                  
                </VStack>  
				<VStack spacing={isMobile ? '0px' : '20px'}>
     {/*               {pageModel.IntermediaryFor &&*/}
					{/*	<PageSection icon={transferValueIcon} title="Intermediary For" description="Transfer is available between these Resources">*/}
     {/*                   <OutlineList items={pageModel.IntermediaryFor?.Values} />*/}
					{/*	</PageSection>*/}
					{/*}*/}


					<RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that own or approve of the " + typeLabel} />
                    {(pageModel.RequiresCompetencies?.Values?.length > 0 ) &&
                        <PageSectionItem>
                            <ModalButtonAndWindow
                                buttonLabel={"Requires " + pageModel.RequiresCompetencies?.Total + " " + widgetGetPluralLabelForText("Competencies")}
                                resourceTitle={pageModel.Name}
                                items={pageModel.RequiresCompetencies?.Values}
                                Wrapper={Section}
                                Content={() => <CompetencyFrameworkList frameworkList={pageModel.RequiresCompetencies} />}
                                hideCount={true}
                            />
                        </PageSectionItem>
                    }
                </VStack>              
            </Wrapper>
            <FullWidthWrapper>
                <style type="text/css">{`
					.competency { border-top: 1px solid #CCC; }
					.competency:empty { display: none; }
					.competency .competencyExtras:empty { display: none; }
					.competency .competencyExtras .competencyExtraListWrapper:not(.expanded) .competencyExtraListSummaryButton svg.expandedIcon { display: none; }
					.competency .competencyExtras .competencyExtraListWrapper.expanded .competencyExtraListSummaryButton svg.collapsedIcon { display: none; }
					.competency .competencyExtras .competencyExtraListWrapper:not(.expanded) .competencyExtraListValues .competencyExtraListItem:not(:first-child) { display: none; }
					.competency .competencyChildren { padding-left: 25px; }
				`}</style>
                <TabSetOrSingle
                    activeIndex={0}
                    items={[
                        {
                            Label: "Intermediary For " + widgetGetPluralLabelForText("Transfer Value Profiles"),
                            Content: (
                               
                                        <PageSection icon={transferValueIcon} title="Intermediary For" description="Transfer is available between these Resources">
                                            <OutlineList items={pageModel.IntermediaryFor?.Values} />
                                        </PageSection>
                              
                            )
                        },
                        {
                            Label: "Details",
                            Content: (
                                <InnerWrapper>
                                    <VStack>
										<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Additional information about the " + typeLabel} />
                                    </VStack>
                                    <VStack>
                                        <div></div>
                                    </VStack>
                                </InnerWrapper>
                            )
                        }
                    ].filter(m => m.Content != null)}
                />
            </FullWidthWrapper>
        </LoadingOrError>
    );
}
//