import React, { ReactNode, useEffect, useState, useContext } from 'react';
import styled from 'styled-components/macro';
import { Button } from '../common';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { legacySourceUrl } from '../../shared/globals';
import { Assessment,
	 	CompetencyFramework,
	  	ConceptScheme,
	   	Credential,
	    LearningOpportunity,
		Organization,
		Pathway,
		PathwaySet,
		TransferValue,
		TransferIntermediary,
		Collection,
		SupportService,
		ScheduledOffering,
		Occupation,
		Job,
		Task,
		WorkRole,
		ProgressionModel,
		DataSetProfile,
		CredentialingAction,
		Rubric,
		Industry,
		Metric
	 } from '../../types/external';
//
import assessmentIcon from '../../assets/images/icons/icon-assessments-blue-green-01.svg';
import competencyFrameworkIcon from '../../assets/images/icons/icon-competencies-blue-green-01.svg';
import conceptSchemeIcon from '../../assets/images/UpdatedIcons/CE-Icon_18-Concept-Scheme_Blue-Green_High-Res.png';
import courseIcon from '../../assets/images/UpdatedIcons/CE-Icon_3-Course_Blue-Green_High-Res.png';
import credentialIcon from '../../assets/images/icons/icon-credentials-blue-green-01.svg';
import jobIcon from '../../assets/images/UpdatedIcons/CE-Icon_13-Jobs_Blue-Green_High-Res.png';
import learningOpportunityIcon from '../../assets/images/UpdatedIcons/CE-Icon_3-Learning-Opportunity_Blue-Green_High-Res.png';
import learningProgramIcon from'../../assets/images/UpdatedIcons/CE-Icon_3-Learning-Program_Blue-Green_High-Res.png';
import occupationIcon from '../../assets/images/UpdatedIcons/CE-Icon_11-Occupations_Blue-Green_High-Res.png';
import outcomeDataIcon from '../../assets/images/UpdatedIcons/CE-Icon_8-Outcome-Data_Blue-Green_High-Res.png';
import organizationIcon from '../../assets/images/icons/icon-organization-blue-green-01.svg';
import pathwayIcon from '../../assets/images/icons/icon-pathways-blue-green-01.svg';
import pathwaySetIcon from '../../assets/images/icons/icon-pathwaysets-blue-green-01.svg';
import progressionModelIcon from  '../../assets/images/UpdatedIcons/CE-Icon_19-Progression-Model_Blue-Green_High-Res.png';
import supportServiceIcon from '../../assets/images/UpdatedIcons/CE-Icon_10-Support_Blue-Green_High-Res.png';
import taskIcon from '../../assets/images/UpdatedIcons/CE-Icon_15-Tasks_Blue-Green_High-Res.png';
import transferValueIcon from '../../assets/images/icons/icon-transfer-value-blue-green-01.svg';
import workRoleIcon from '../../assets/images/UpdatedIcons/CE-Icon_14-Work-Roles_Blue-Green_High-Res.png';
import qaActionIcon from '../../assets/images/UpdatedIcons/CE-Icon_20-QA-Actions_Blue-Green_High-Res.png';
import rubricIcon from '../../assets/images/UpdatedIcons/CE-Icon_21-Rubrics_Blue-Green_High-Res.png';
import metricIcon from '../../assets/images/UpdatedIcons/CE-Icon_22-Metrics_Blue-Green_High-Res.png';
import industryIcon from '../../assets/images/UpdatedIcons/CE-Icon_12-Industries_Blue-Green_High-Res.png';
import collectionIcon from '../../assets/images/UpdatedIcons/CE-Icon_6-Collection_Blue-Green_High-Res.png';
import transferIntermediaryIcon from '../../assets/images/UpdatedIcons/CE-Icon_17-Transfer-Intermediary_Blue-Green_High-Res.png';
import scheduledOfferingIcon from '../../assets/images/UpdatedIcons/CE-Icon_9-Scheduled-Offering_Blue-Green_High-Res.png';

import { MainSearchType } from '../../types/internal';
import { getCompareItems, addCompareItem, removeCompareItem, updateStateOnCompareChange, getCompareItemByCTID } from '../../utils/LocalStorage';
import { widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";


interface SearchResultItemProps {
	type: string;
	item: any;
	showFooter?: boolean;
	showBody?: boolean;
	locations?: Array<any>;
	onLocationButtonClick?: () => void | undefined;
}

export default function SearchResultItem(props: SearchResultItemProps) {
	const [compareItems, setCompareItems] = useState(getCompareItems());

	useEffect(() => {
		updateStateOnCompareChange(() => { setCompareItems([...getCompareItems()]); });
		setCompareItems(getCompareItems());
	});

	async function addItemToCompareBucket() {
		getCompareItems().find((item: any) => item.CTID == props.item.CTID) == null ? addCompareItem(props.item) : removeCompareItem(props.item);
	}

	function isComparable() {
		return props.type !== 'pathway' &&
		 props.type !== 'pathwayset' && 
		 props.type !== 'competencyframework' && 
		 props.type !== 'transfervalue' && 
		 props.type !== 'transferintermediary' && 
		 props.type !== 'conceptscheme' && 
		 props.type !== 'progressionmodel' && 
		 props.type !== 'outcomedata' && 
		 props.type !== 'supportservice' && 
		 props.type !== 'occupation' && 
		 props.type !== 'job' && 
		 props.type !== 'task' && 
		 props.type !== 'workrole' && 
		 props.type !=='credentialingaction' && 
		 props.type !=='rubric' && 
		 props.type !=='metric' && 
		 props.type !=='industry';
	}

	function getContent() {
		switch (props.type) {
			case 'organization': return <OrganizationSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'credential': return <CredentialSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'assessment': return <AssessmentSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'learningopportunity': return <LearningOpportunitySearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'pathway': return <PathwaySearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'pathwayset': return <PathwaySetSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'competencyframework': return <CompetencyFrameworkSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'scheduledoffering': return <ScheduledOfferingSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'supportservice': return <SupportServiceSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'outcomedata': return <OutcomeDataSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;

			case 'occupation': return <OccupationSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'job': return <JobSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'task': return <TaskSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'workrole': return <WorkRoleSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;

			case 'transfervalue': return <TransferValueSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'transferintermediary': return <TransferIntermediarySearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'conceptscheme': return <ConceptSchemeSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'collection': return <CollectionSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'progressionmodel': return <ProgressionModelSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'credentialingaction': return <CredentialingActionSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'rubric': return <RubricSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'metric': return <MetricSearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			case 'industry': return <IndustrySearchResultItem item={props.item} showBody={props.showBody} showFooter={props.showFooter} />;
			default: return null;
		}
	}

	return (
		<Wrap>
			{getContent()}
			<ResultButtonWrapper>
				{isComparable() && (
					<CompareButton buttonType="secondary" onClick={addItemToCompareBucket}>
						{getCompareItemByCTID(props.item.CTID) == null ? (
							<>
								<ButtonText>Compare</ButtonText>{' '}
								<ButtonIcon>
									<FontAwesomeIcon icon={faPlus} />
								</ButtonIcon>
							</>
						) : (
							<SButtonIcon>
								<FontAwesomeIcon icon={faCheck} />
							</SButtonIcon>
						)}
					</CompareButton>
				)}
				<LocationsButton locations={props.locations} onLocationButtonClick={props.onLocationButtonClick} />
			</ResultButtonWrapper>
		</Wrap>
	);
}

function LocationsButton(props: { locations?: Array<any>, onLocationButtonClick?: () => void | undefined }) {
	return props.locations && props.locations.length > 0 && (
		<LocationsButtonWrapper onClick={props.onLocationButtonClick}>
			{props.locations.length == 1 ? "1 Location" : props.locations.length + " Locations"}
			<FontAwesomeIcon icon={faMapMarkerAlt} />
		</LocationsButtonWrapper>
	) || null;
}

export function OrganizationSearchResultItem(props: { item: Organization, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={props.item.Image || ''}
			fallBackIcon={organizationIcon}
			title={props.item.Name}
			titleUrl={`/organization/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			body={props.item.Description}
			showBody={props.showBody}
			showFooter={props.showFooter}
			lastUpdated={props.item.Meta_LastUpdated}
		/>
	);
}

export function CredentialSearchResultItem(props: { item: Credential, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={props.item.Image || props.item.Meta_Icon || ''}
			fallBackIcon={credentialIcon}
			preTitle={widgetGetSingleLabelForText(props.item.CTDLTypeLabel, props.item.CTDLTypeLabel) + (props.item.Meta_HasVerificationBadge ? " + Badge Issued" : "")}
			title={props.item.Name}
			titleUrl={`/credential/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
			hasBadge={props.item.Meta_HasVerificationBadge}
		/>
	);
}

export function AssessmentSearchResultItem(props: { item: Assessment, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={assessmentIcon}
			title={props.item.Name}
			titleUrl={`/assessment/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}

export function LearningOpportunitySearchResultItem(props: { item: LearningOpportunity, showFooter?: boolean, showBody?: boolean }) {
	var icon = ({
		"ceterms:LearningOpportunityProfile": learningOpportunityIcon,
		"ceterms:LearningProgram": learningProgramIcon,
		"ceterms:Course": courseIcon
	} as any)[props.item.CTDLType] || learningOpportunityIcon;

	return (
		<BaseResultItem
			icon={icon}
			title={props.item.Name}
			preTitle={widgetGetSingleLabelForText(props.item.CTDLTypeLabel, props.item.CTDLTypeLabel)}
			titleUrl={`/learningopportunity/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}

export function CompetencyFrameworkSearchResultItem(props: { item: CompetencyFramework, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={competencyFrameworkIcon}
			title={props.item.Name}
			titleUrl={`/competencyframework/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}

export function PathwaySearchResultItem(props: { item: Pathway, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={pathwayIcon}
			title={props.item.Name}
			titleUrl={`/pathway/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}

export function PathwaySetSearchResultItem(props: { item: PathwaySet, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={pathwaySetIcon}
			title={props.item.Name}
			titleUrl={`/pathwayset/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function ScheduledOfferingSearchResultItem(props: { item: ScheduledOffering, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={scheduledOfferingIcon}
			title={props.item.Name}
			titleUrl={`/ScheduledOffering/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function SupportServiceSearchResultItem(props: { item: SupportService, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={supportServiceIcon}
			title={props.item.Name}
			titleUrl={`/SupportService/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function OutcomeDataSearchResultItem(props: { item: DataSetProfile, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={outcomeDataIcon}
			title={props.item.Name}
			titleUrl={`/outcomeData/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function JobSearchResultItem(props: { item: Job, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={jobIcon}
			title={props.item.Name}
			titleUrl={`/Job/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function OccupationSearchResultItem(props: { item: Occupation, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={occupationIcon}
			title={props.item.Name}
			titleUrl={`/Occupation/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function WorkRoleSearchResultItem(props: { item: WorkRole, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={workRoleIcon}
			title={props.item.Name}
			titleUrl={`/WorkRole/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function TaskSearchResultItem(props: { item: Task, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={taskIcon}
			title={props.item.Name}
			titleUrl={`/Task/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function CredentialingActionSearchResultItem(props: { item: CredentialingAction, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={qaActionIcon}
			preTitle={widgetGetSingleLabelForText(props.item.CTDLTypeLabel, props.item.CTDLTypeLabel)}
			title={props.item.Name}
			titleUrl={`/CredentialingAction/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function RubricSearchResultItem(props: { item: Rubric, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={rubricIcon}
			title={props.item.Name}
			titleUrl={`/Rubric/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function MetricSearchResultItem(props: { item: Metric, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={metricIcon}
			title={props.item.Name}
			titleUrl={`/Metric/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function IndustrySearchResultItem(props: { item: Industry, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={industryIcon}
			title={props.item.Name}
			titleUrl={`/Industry/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function TransferValueSearchResultItem(props: { item: TransferValue, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={transferValueIcon}
			title={props.item.Name}
			titleUrl={`/transfervalue/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
			headerExtraText={[props.item.StartDate ? "Start Date: " + props.item.StartDate : "", props.item.EndDate ? "End Date: " + props.item.EndDate : ""].filter(m => m).join(" - ")}
		/>
	);
}
export function TransferIntermediarySearchResultItem(props: { item: TransferIntermediary, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={transferIntermediaryIcon}
			title={props.item.Name}
			titleUrl={`/transferIntermediary/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function ConceptSchemeSearchResultItem(props: { item: ConceptScheme, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={conceptSchemeIcon}
			title={props.item.Name}
			titleUrl={`/conceptscheme/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}
export function ProgressionModelSearchResultItem(props: { item: ProgressionModel, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={progressionModelIcon}
			title={props.item.Name}
			titleUrl={`/progressionmodel/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}

export function CollectionSearchResultItem(props: { item: Collection, showFooter?: boolean, showBody?: boolean }) {
	return (
		<BaseResultItem
			icon={collectionIcon}
			title={props.item.Name}
			titleUrl={`/collection/${props.item.Meta_Id}/${props.item.Meta_FriendlyName || ''}`}
			subTitle={props.item.OwnedByLabel?.Label}
			subTitleUrl={props.item.OwnedByLabel?.URL}
			body={props.item.Description}
			lastUpdated={props.item.Meta_LastUpdated}
			showBody={props.showBody}
			showFooter={props.showFooter}
		/>
	);
}

interface BaseResultItemProps {
	icon: string;
	hasBadge?: boolean;
	fallBackIcon?: string;
	preTitle?: string;
	title: string;
	subTitle?: string;
	body: string;
	footer?: ReactNode;
	titleUrl?: string;
	subTitleUrl?: string;
	showFooter?: boolean;
	showBody?: boolean;
	lastUpdated?: string;
	headerExtraText?: string;
}

export function BaseResultItem(props: BaseResultItemProps) {
	const [showBody, setShowBody] = useState<boolean>(props.showBody || false);
	const [showFooter, setShowFooter] = useState<boolean>(props.showFooter || false);
	const [showMoreBody, setShowMoreBody] = useState(false);

	function onLoadingIconError(e: any) {
		e.target.src = props.fallBackIcon;
	}

	function renderBody() {
		const body = props.body || '';
		if (showMoreBody) {
			return <Body>{body}</Body>;
		} else {
			if (body.length > 300) {
				return (
					<Body>
						{body.substring(0, 300) + ' '}
						<MoreButton onClick={() => setShowMoreBody(true)}>...more...</MoreButton>
					</Body>
				);
			}
			return <Body>{body.substring(0, 300)}</Body>;
		}
	}

	useEffect(() => {
		setShowBody(props.showBody || false);
		setShowFooter(props.showFooter || false);
	}, [props.showBody, props.showFooter]);

	return (
		<ContentWrap className={showBody ? 'open' : ''}>
			<Header>
				<div>
					<IconWrapper>
						<Icon alt="" src={props.icon} onError={onLoadingIconError} />
						{props.hasBadge && <BadgeIcon src={legacySourceUrl + "/images/icons/flat_badge_cropped.png"} />}
					</IconWrapper>
					{/*<Toggler onClick={() => setShowBody(!showBody)} type="button">Show {showBody ? 'Less' : 'More'}</Toggler>*/}
				</div>
				<div>
					<PreTitle>{props.preTitle}</PreTitle>
					<Title to={props.titleUrl || '#'} target="detailPage">{props.title}</Title>
					{props.subTitleUrl && <SubTitle to={props.subTitleUrl || '#'} target="detailPage">{props.subTitle}</SubTitle>}
					<LastUpdated>Last Updated {props.lastUpdated}</LastUpdated>
					{props.headerExtraText && <HeaderExtraText>{props.headerExtraText}</HeaderExtraText>}
				</div>
			</Header>
			{renderBody()}
			{showFooter && props.footer && <Footer>{props.footer}</Footer>}
		</ContentWrap>
	);
}

const Toggler = styled.button`
    background: none;
    border: 0 none;
    bottom: 0;
    box-shadow: none;
    color: ${(p) => p.theme.color.brown};
    left: 0;
    padding: 0;
    position: absolute;
    text-decoration: underline;

    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        position: static;
    }
`;

const MoreButton = styled.span`
    color: ${(p) => p.theme.color.brown};
    cursor: pointer;
`;

const Header = styled.div`
    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        display: grid;
        gap: 20px;
        grid-template-columns: 80px 1fr;
    }
`;

const Icon = styled.img`
    height: auto;
    left: 0;
    position: absolute;
    top: 0;
    width: 60px;

    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        position: static;
        width: 80px;
    }
`;

const PreTitle = styled.div`
    left: 80px;
    position: absolute;
    top: 0;
    margin-right: 60px;

    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        margin: 0;
        position: static;
    }
`;

const Title = styled(Link)`
    margin: 0 0 0.5rem;
    cursor: pointer;
    font-size: ${(props) => props.theme.fontSize.xxl};
    line-height: 1.1667;
    font-weight: bold;
    text-decoration: none;
`;

const SubTitle = styled(Link)`
    display: block;
    font-size: ${(props) => props.theme.fontSize.lg};
    line-height: 1.125;
    font-weight: 700;
    cursor: pointer;
    text-decoration: none;
`;

const Body = styled.div`
    margin-top: 1rem;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.15s ease-out;
`;

const Footer = styled.div`
    gap: 10px;
    overflow: hidden;
    margin-top: 1rem;
    max-height: 0;
    transition: max-height 0.25s ease-out;

    button {
        margin: 0.5rem 0;
    }

    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        display: flex;
    }
`;

const Wrap = styled.div`
    padding: 80px 0 1.5rem;
    position: relative;

    &.open {
        ${Body},
        ${Footer} {
            max-height: 500px;
            transition: max-height 0.25s ease-in;
        }
    }

    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr auto;
        padding: 0;
    }
`;

const ContentWrap = styled.div`
    &.open {
        ${Body},
        ${Footer} {
            max-height: 500px;
            transition: max-height 0.25s ease-in;
        }
    }
`;

const CompareButton = styled(Button)`
    display: flex;
    align-items: center;
    height: 40px;
    min-width: 40px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;

    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        position: static;
    }
`;

const ButtonText = styled.span`
    align-items: center;
    display: none;
    justify-content: center;
    padding: 0 1.65em;

    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        display: flex;
    }
`;

const ButtonIcon = styled.span`
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: center;
    width: 40px;

    @media (min-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        border-left: 1px solid ${(p) => p.theme.color.white};
    }
`;

const SButtonIcon = styled(ButtonIcon)`
    border: none;
	margin: 0 auto;
`;

const IconWrapper = styled.div`
	position: relative;

    @media (max-width: ${(p) => p.theme.breakpoint.tabletPortrait}) {
        display: none;
    }
`;

const BadgeIcon = styled.img`
	position: absolute;
	bottom: -5px;
	right: -15px;
	max-width: 50px;
	max-height: 50px;
`;

const LastUpdated = styled.div`
	font-style: italic;
	opacity: 0.8;
	font-size: 85%;
	padding: 2px 0;
`;

const ResultButtonWrapper = styled.div`
	max-width: 165px;
	& > * {
		width: 100%;
		margin-bottom: 5px;
	}
`;

const LocationsButtonWrapper = styled(Button)`
	min-width: 0;
	& > svg { margin-left: 10px; }
`;

const HeaderExtraText = styled.div`

`;
