import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import aboutIcon from '../../assets/images/icons/icon-about-blue-green-01.svg';
import pageIcon from '../../assets/images/UpdatedIcons/CE-Icon_8-Outcome-Data_Blue-Green_High-Res.png';
import Banner from '../../components/Banner';
import { WhiteLink } from '../../components/Banner/styles';
import AdditionalInformationPageSection from '../../components/DetailPage/AdditionalInformationPageSection';
import LinkObject, { PlainTextLink } from '../../components/DetailPage/LinkObject';
import OutlineList from '../../components/DetailPage/Outline';
import RelatedOrganizationPageSection from '../../components/DetailPage/RelatedOrganizationPageSection';
import {
	Description,
	FullWidthWrapper,
	Inline,
	InlineLabel,
	InnerWrapper,
	Label, 
	Wrapper
} from '../../components/DetailPage/styles';
import HasAnyFilled from '../../components/HasAnyFilled';
import LoadingOrError from '../../components/LoadingOrError';
import { ModalButtonAndWindow } from '../../components/Modal';
import PageSection from '../../components/PageSection';
import PageSectionItem from '../../components/PageSectionItem';
import { VStack } from '../../components/Stack';
import { useGet, useMediaQuery } from '../../hooks';
import { apiUrl, appURL, legacySourceUrl } from '../../shared/globals';
import { theme } from '../../shared/theme';
import { DataSetProfile, DataSetService, Link, Observation, ProcessProfile,DataSetDistribution, Dimension, DataSetTimeFrame  } from '../../types/external';
import ProcessProfileList from '../../components/DetailPage/ProcessProfile';
import processIcon from '../../assets/images/icons/icon-process-blue-green-01.svg';
import { detailSectionProperties } from '../../utils';
import connectionsIcon from '../../assets/images/icons/icon-connections-green-blue-01.svg';
import ConnectionsSection from "../../components/DetailPage/ConnectionsSection";
import { ModalType } from "../../types/internal";
import { TabSetOrSingle } from '../../components/DetailPage/TabSelector';
import { widgetGetPluralLabelForText, widgetGetSingleLabelForText } from "../../utils/SiteWidgetizer";
import { DataSetTimeFrameSingle, DataSetTimeFrameSingleMinimal } from '../../components/DetailPage/DataSetProfile';


import { Box, BoxContent, SectionContent, SectionContentItem } from '../../components/DetailPage/Shared';
import styled from 'styled-components';
import { isFilled } from '../../validation';
import SearchPills from '../../components/SearchPills';
import PlaceList from '../../components/DetailPage/Place';
interface Props {
	id: string;
}

export default function DataSetProfilePage(props: RouteComponentProps<Props>) {
	const id = props.match.params.id;
	const [
		pageDataIsLoading,
		pageDataHasError,
		getPageData,
	] = useGet();
	const [pageModel, setPageData] = useState({} as DataSetProfile);
	const isMobile = useMediaQuery({
		query: `(max-width: ${theme.breakpoint.tabletPortrait})`,
	});
	const [selectedDimension, setSelectedDimension] = useState({} as Dimension);

	useEffect(() => {
		getPageData(`${apiUrl}/DataSetProfile/${id}`, (data: DataSetProfile) => {
			setPageData(data);
		});
	}, [getPageData, id]);
	//
	useEffect(() => {
		if (pageModel?.HasDimension?.length > 0) {
		  setSelectedDimension(pageModel.HasDimension[0]);
		}
	  }, [pageModel?.HasDimension]); 
	  
	  const handleDimensionChange = (event: any) => {
		const selectedIndex = Number(event.target.value);
		setSelectedDimension(pageModel?.HasDimension?.[selectedIndex]);
	  };
	  
	  useEffect(() => {
	  }, [selectedDimension]);

	function getSubTitleLink() {
		if (pageModel.DataProvider && pageModel.DataProvider.Label) {
			return pageModel.DataProvider.URL ?
				<WhiteLink href={pageModel.DataProvider.URL}>{pageModel.DataProvider.Label}</WhiteLink> :
				<>{pageModel.DataProvider.Label}</>;
		}
		return null;
	}

	const [openedModal, setOpenedModal] = useState<ModalType>();

	function onOpenModalClick(modalType: ModalType) {
		return () => setOpenedModal(modalType);
	}

	function onCloseModalClick() {
		setOpenedModal(undefined);
	}

	function getLegacyLink() {
		if (pageModel.Meta_Id) {
			const url = legacySourceUrl + "/" + pageModel.BroadType + "/" + pageModel.Meta_Id;
			const mystyle = {
				//color: "rgba(0,0,0,.01)",
				color: "transparent",
				//backgroundColor: "rgba(0,0,0,.01)",
				marginLeft: "10px"
			};
			return <>
				<WhiteLink href={url} target="legacy" style={mystyle} title="View in services application">Legacy</WhiteLink>
			</>

		}
		return null;
	}

	var typeLabel = widgetGetSingleLabelForText("Outcome Data");
	
	return (
		<LoadingOrError
			isLoading={pageDataIsLoading}
			hasError={pageDataHasError}>

			<Banner
				icon={pageIcon}
				fallBackIcon={pageIcon}
				type={pageModel.CTDLTypeLabel}
				title={pageModel.Name}
				subTitle={getSubTitleLink()}
				content={
					<small>Last updated: {pageModel.Meta_LastUpdatedHeader} <span  >{getLegacyLink()}</span></small>
				}
			/>
			<Wrapper>
				<VStack spacing={isMobile ? '0px' : '20px'}>
					<HasAnyFilled
						data={pageModel}
						keys={detailSectionProperties.dataSetProfileAbout}>
						<PageSection
							icon={aboutIcon}
							title={"About this " + typeLabel}
							variant="Highlight"
							description={"Basic information about this " + typeLabel}>

							{pageModel.Description && (
								<PageSectionItem>
									<Description>
										{pageModel.Description}
									</Description>
								</PageSectionItem>
							)}
							{
								pageModel.About?.length > 0 &&
								<PageSectionItem>
									<Label>About</Label>
									<OutlineList items={pageModel.About} />
								</PageSectionItem>
							}
							{pageModel.Source &&
								<PageSectionItem>
									<LinkObject item={{ URL: pageModel.Source, Label: "View this " + typeLabel }} />
								</PageSectionItem>
							}
							{pageModel.DataSuppressionPolicy && (
								<PageSectionItem>
									<Label>Data Suppression Policy</Label>
									<div>{pageModel.DataSuppressionPolicy}</div>								
								</PageSectionItem>
							)}
							{pageModel.SubjectIdentification && (
								<PageSectionItem>
									<Label>Subject Identification</Label>
									<div>{pageModel.SubjectIdentification}</div>								
								</PageSectionItem>
							)}

							{pageModel.DistributionFile?.length > 0 &&
								pageModel.DistributionFile.map((url) => (
									<LinkObject item={{ URL: url, Label: "Distribution File" }} />
								))
								}
							{pageModel.DataSetSpatialCoverage?.length > 0 &&
							<PageSectionItem>
							<Label>DataSet Spatial Coverage</Label>
							<PlaceList items={pageModel.DataSetSpatialCoverage} />
							</PageSectionItem>
							}
								{pageModel.DataSetTemporalCoverage && 
								<Label>Data Set Temporal Coverage</Label>
								}
								<PageSectionItem>
									{pageModel.DataSetTemporalCoverage?.Name &&
									<><Label>Name</Label><div>{pageModel.DataSetTemporalCoverage?.Name}</div></>
									}
									{pageModel.DataSetTemporalCoverage?.Description &&
									<><Label>Description</Label><div>{pageModel.DataSetTemporalCoverage?.Description}</div></>
									}
									{pageModel.DataSetTemporalCoverage?.AlternateName &&
									<><Label>AlternateName</Label><div>{pageModel.DataSetTemporalCoverage?.AlternateName}</div></>
									}
									{isFilled(pageModel.DataSetTemporalCoverage?.DataSourceCoverageType) && (
										<><Label>Keywords</Label>
									<SearchPills links={pageModel.DataSetTemporalCoverage?.DataSourceCoverageType} /></>
									)}
									{pageModel.DataSetTemporalCoverage?.StartDate &&
									<><Label>Start Date</Label><div>{pageModel.DataSetTemporalCoverage?.StartDate}</div></>
									}
									{pageModel.DataSetTemporalCoverage?.EndDate &&
									<><Label>End Date</Label><div>{pageModel.DataSetTemporalCoverage?.EndDate}</div></>
									}
									{pageModel.DataSetTemporalCoverage?.TimeIntervalDisplay &&
									<><Label>Time Interval Display</Label><div>{pageModel.DataSetTemporalCoverage?.TimeIntervalDisplay}</div></>
									}
								</PageSectionItem>
						</PageSection>
						

						
					</HasAnyFilled>
				</VStack>
				<VStack spacing={isMobile ? '0px' : '20px'}>
					<RelatedOrganizationPageSection item={pageModel} pageSectionDescription={widgetGetPluralLabelForText("Organizations") + " that offer the " + typeLabel} />

				</VStack>
			</Wrapper>
			<FullWidthWrapper>
				<TabSetOrSingle
					activeIndex={0}
					items={[
						{
							Label: "Metrics",
							Content: (
								<>
								{pageModel.DataSetTimePeriod?.length === 1 &&
									<PageSection icon={connectionsIcon}
									title={"More Specific " + widgetGetPluralLabelForText("Outcome Data")}
									description={"Specific " + widgetGetPluralLabelForText("Outcome Data") + " that are part of this " + typeLabel}>
									{/*Label: "Coming Soon!"*/}
									<TabSetOrSingle
										activeIndex={0}
										items={pageModel.DataSetTimePeriod.map(
											(dataSetProfile, index) => (
												{
													Label: dataSetProfile.Name || ("Data Set " + (index + 1)),
													Content: <DataSetTimeFrameSingleMinimal item={dataSetProfile} />
												}
											)
										)} />
								</PageSection>
								}
								<div style={styles.tableContainer}>
										<table style={styles.table}>
											<thead>
												<tr>
													{pageModel?.HasDimension?.length > 0 ?
														<th style={styles.headerCell}><b>Dimensions </b></th> : null}
													{pageModel?.HasDimension?.length > 0 ? (
														<th style={styles.headerCell} colSpan={selectedDimension?.HasPointSummary?.length || 1}>
															{pageModel?.HasDimension?.length>1 && <label>Choose a dimension from the dropdown: </label>}
															{pageModel?.HasDimension?.length > 1 ? (
																<select
																	onChange={handleDimensionChange}
																	style={styles.dropdown}
																>
																	{pageModel.HasDimension.map((dim, idx) => (
																		<option key={idx} value={idx}>
																			{dim.Name}
																		</option>
																	))}
																</select>
															) : (
																<b> {selectedDimension && (
																	<TextDisplay title="Dimenstion Name">
																		{selectedDimension.Name}
																	</TextDisplay>
																)}
																</b>
															)}
															{selectedDimension.Description && (
																<TextDisplay title="Dimenstion Description">
																	{selectedDimension.Description}
																</TextDisplay>
															)}
															{selectedDimension.DimenstionType && (
																<TextDisplay title="Dimenstion Type">
																	{selectedDimension.DimenstionType}
																</TextDisplay>
															)}
														</th>
													) : null}
												</tr>
												{selectedDimension && (
													<tr>
														<th style={styles.headerCell}><b>Metrics </b></th>
														{selectedDimension.HasPointSummary?.map((point, index) => (
															<th key={index} style={styles.headerCell}>
																{point.Label && (
																	<TextDisplay title="Point Label">
																		<b>Label: </b>
																		{point?.ResourceSummary?.Type && point?.ResourceSummary?.Id > 0 ? (
																			<a href={`${appURL}/${point.ResourceSummary.Type}/${point.ResourceSummary.Id}`}>
																				{point.Label}
																			</a>
																			) : (
																			point.Label
																			)}
																	</TextDisplay>
																)}
																{point.Type && (
																	<TextDisplay title="Point Type">
																		<b>Point Type: </b> {point.Type}
																	</TextDisplay>
																)}
															</th>
														))}
													</tr>
												)}
											</thead>
											<tbody>
												{pageModel?.HasMetric?.map((metric, rowIndex) => (
													<tr key={rowIndex}>
														<td style={styles.headerCell}>
															<a href={`${appURL}/${metric.CTDLTypeLabel}/${metric.Meta_Id}`}>
																{metric.Name}
															</a>
															{metric?.CTID && (
																<TextDisplay title="CTID">
																	<b>CTID: </b> {metric?.CTID}
																</TextDisplay>
															)}
															{metric?.MetricType && (
																<TextDisplay title="Metric Type">
																	<b>Metric Type: </b> {metric.MetricType[0]?.Label}
																</TextDisplay>
															)}
															{metric?.Description && (
																<TextDisplay title="Description">
																	<b>Description: </b> {metric?.Description}
																</TextDisplay>
															)}
														</td>
														{selectedDimension?.HasPointSummary?.length > 0 ? (
															selectedDimension.HasPointSummary.map((point) => {
																const observations = pageModel.HasObservation?.filter(
																	(obs) => obs.IsObservationOf?.Meta_Id === metric.Meta_Id &&
																		obs.AtPoints?.some((p) => p.RowId === point.RowId)
																);
																return (
																	<td key={`point-${point.RowId}`} style={styles.dataCell}>
																		{observations?.length > 0 ? (
																			observations.map((observation, obsIndex) => (
																				<div key={`obs-${obsIndex}`}>
																					{renderObservation(observation, point.EntityTypeId, pageModel.DataSetTimePeriod)}
																					{obsIndex < observations.length - 1 && (
																						<hr style={{ margin: "8px 0", border: "1px solid #ccc" }} />
																					)}
																				</div>
																			))
																		) : (
																			null
																		)}
																	</td>
																);
															})
														) : (
															pageModel?.HasObservation?.map((observation, obsIndex) => {
																if (observation.IsObservationOf?.Meta_Id === metric.Meta_Id) {
																	return (
																		<td key={`obs-${obsIndex}`} style={styles.dataCell}>
																			{renderObservation(observation,0, pageModel.DataSetTimePeriod)}
																		</td>
																	);
																}
																return null;
															})
														)}
													</tr>
												))}
											</tbody>
										</table>
									</div></>
								)
						},
						{
							Label: "Data Sets",
							Content: (
								(
									pageModel.DataSetTimePeriodHistoricalData?.length > 0 &&
									<PageSection icon={connectionsIcon}
											title={"More Specific " + widgetGetPluralLabelForText("Outcome Data")}
											description={"Specific " + widgetGetPluralLabelForText("Outcome Data") + " that are part of this " + typeLabel}>
										{/*Label: "Coming Soon!"*/}
										<TabSetOrSingle
											activeIndex={0}
											items={pageModel.DataSetTimePeriodHistoricalData.map(
												(dataSetProfile, index) => (
													{
														Label: dataSetProfile.Name || ("Data Set " + (index + 1) ),
														Content: <DataSetTimeFrameSingle item={dataSetProfile} />
													}
												)
											)}
										/>
									</PageSection>
								) || null
							)
						},
						{
							Label: "Details",
							Content: (
								<InnerWrapper>
									<VStack>										
										<AdditionalInformationPageSection item={pageModel} pageSectionDescription={"Identifiers and other information for the " + typeLabel} />
									</VStack>
									<VStack>
										<HasAnyFilled
											data={pageModel}
											keys={[
												...detailSectionProperties.entityConnections
											]}>
											<PageSection
												icon={connectionsIcon}
												title="Connections"
												description="Related Resources"
											>
												<PageSectionItem>
													<ConnectionsSection
														item={pageModel}
														openedModal={openedModal}
														onOpenModalClick={onOpenModalClick}
														onCloseModalClick={onCloseModalClick}
													/>
												</PageSectionItem>
											</PageSection>
										</HasAnyFilled>
										
										<HasAnyFilled
											data={pageModel}
											keys={detailSectionProperties.dataSetProfileProcess}>
											<PageSection icon={processIcon} title="Process" description={"Processes related to the " + typeLabel}>

												<HasAnyFilled
													data={pageModel}
													keys={detailSectionProperties.dataSetProfileProcess}>
													<PageSectionItem>
													<ModalButtonAndWindow
															buttonLabel={pageModel.AdministrationProcess?.Label}
															Content={(props: { loadedData: any }) => (
																<ProcessProfileList items={pageModel.AdministrationProcess.Values as Array<ProcessProfile>} />
															)}
															modalTitle={pageModel.AdministrationProcess?.Label}
															resourceTitle={pageModel.Name}
															items={pageModel.AdministrationProcess?.Values}
															overrideCount={pageModel.AdministrationProcess?.Total}
														/>
														<ModalButtonAndWindow
															buttonLabel={pageModel.DevelopmentProcess?.Label}
															Content={(props: { loadedData: any }) => (<ProcessProfileList items={pageModel.DevelopmentProcess.Values as Array<ProcessProfile>} />)}
															modalTitle={pageModel.DevelopmentProcess?.Label}
															resourceTitle={pageModel.Name}
															items={pageModel.DevelopmentProcess?.Values}
															overrideCount={pageModel.DevelopmentProcess?.Total}
														/>
														<ModalButtonAndWindow
															buttonLabel={pageModel.MaintenanceProcess?.Label}
															getDataOnClickFromURL={pageModel.MaintenanceProcess?.URL}
															Content={(props: { loadedData: any }) => (<ProcessProfileList items={pageModel.MaintenanceProcess.Values as Array<ProcessProfile>} />)}
															modalTitle={pageModel.MaintenanceProcess?.Label}
															resourceTitle={pageModel.Name}
															items={pageModel.MaintenanceProcess?.Values}
															overrideCount={pageModel.MaintenanceProcess?.Total}
														/>
														<ModalButtonAndWindow
															buttonLabel={pageModel.ReviewProcess?.Label}
															getDataOnClickFromURL={pageModel.ReviewProcess?.URL}
															Content={(props: { loadedData: any }) => (<ProcessProfileList items={pageModel.ReviewProcess.Values as Array<ProcessProfile>} />)}
															modalTitle={pageModel.ReviewProcess?.Label}
															resourceTitle={pageModel.Name}
															items={pageModel.ReviewProcess?.Values}
															overrideCount={pageModel.ReviewProcess?.Total}
														/>

													</PageSectionItem>
												</HasAnyFilled>

											</PageSection>
										</HasAnyFilled>
										{pageModel.HasDataSetDistribution &&
											<PageSection icon={connectionsIcon} title="Has Data Set Distribution" description={"This " + typeLabel + " has a data set distribution"}>
											<DataSetDistributionComponent items={pageModel.HasDataSetDistribution} Name={pageModel.Name}  />
											</PageSection>
										}
										{pageModel.HasDataSetService &&
											<PageSection icon={connectionsIcon} title="Has Data Set Service" description={"This " + typeLabel + " has a data set service"}>
											<DataSetServiceComponent items={pageModel.HasDataSetService} Name={pageModel.Name}  />
											</PageSection>
										}
									</VStack>
								</InnerWrapper>
							)
						}
						
					]}
				/>
			</FullWidthWrapper>
		</LoadingOrError>
	);
}

export function DataSetDistributionComponent(props: { items: DataSetDistribution[], Name:string }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => setIsOpen(!isOpen);

    return (
        <div>
            {props.items?.length > 0 && (
                <ModalButtonAndWindow
					buttonLabel={`Data Set Distribution`}
					modalTitle="Data Set Distribution"
					resourceTitle={props.Name}
					items={props.items}
					onButtonClick={toggleModal}
					Content={({ loadedData }) => (
						<div>
							{props.items.map((item, index) => (
							<Box>
								<BoxContent key={index}>
								<SectionContent>
									<SectionContentItem label="Name" data={item.Name} RenderWith={(data) => <div>{data}</div>} />
									<SectionContentItem label="Description" data={item.Description} RenderWith={(data) => <div>{data}</div>} />
									<SectionContentItem label="Date Effective" data={item.DateEffective} RenderWith={(data) => <div>{data}</div>} />
									<SectionContentItem label="Alternate Name(s):" data={item.AlternateName?.join(", ")} RenderWith={(data) => <div>{data}</div>} />
									<SectionContentItem label="Media Type" data={item.MediaType} RenderWith={(data) => <div>{data}</div>} />
									<SectionContentItem label="Rights" data={item.Rights} RenderWith={(data) => <div>{data}</div>} />
									{item.DistributionFile && <LinkItem label="DistributionFile:" item={item.DistributionFile} />}
									{item.License.length > 0 && item.License.map((license: string, licenseIndex: number) => (
										<LinkItem key={licenseIndex} label="License:" item={license} />
									))}
									{item.Source.length > 0 && item.Source.map((source: string, sourceIndex: number) => (
										<LinkItem key={sourceIndex} label="Source:" item={source} />
									))}
								</SectionContent>
								</BoxContent>
							</Box>
								
							))}
						</div>
					)}          />
            )}
        </div>
    );
}

export function DataSetServiceComponent(props: { items: DataSetService[], Name:string }) {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => setIsOpen(!isOpen);

    return (
        <div>
            {props.items?.length > 0 && (
                <ModalButtonAndWindow
					buttonLabel={`Data Set Service`}
					modalTitle="Data Set Service"
					resourceTitle={props.Name}
					items={props.items}
					onButtonClick={toggleModal}
					Content={({ loadedData }) => (
						<div>
							{props.items.map((item, index) => (
							<Box>
								<BoxContent key={index}>
								<SectionContent>
								<SectionContentItem label="Name" data={item.Name} RenderWith={(data) => <div>{data}</div>} />
									<SectionContentItem label="Description" data={item.Description} RenderWith={(data) => <div>{data}</div>} />
									<SectionContentItem label="Alternate Name(s):" data={item.AlternateName?.join(", ")} RenderWith={(data) => <div>{data}</div>} />
									<SectionContentItem label="Rights" data={item.Rights} RenderWith={(data) => <div>{data}</div>} />
									{item.SubjectWebpage && <LinkItem label="SubjectWebpage:" item={item.SubjectWebpage} />}
									{item.License.length > 0 && item.License.map((license: string, licenseIndex: number) => (
										<LinkItem key={licenseIndex} label="License:" item={license} />
									))}
								</SectionContent>
								</BoxContent>
							</Box>
								
							))}
						</div>
					)}          />
            )}
        </div>
    );
}
function LinkItem(props: { label: string, item: any }) {
	if (!props.item) {
		return null;
	}

	var link = typeof (props.item) == "string" ? ({ Label: props.item, URL: props.item } as Link) : (props.item as Link);
	return (link.URL &&
		<Inline>
			<InlineLabel>{props.label}</InlineLabel>
			<PlainTextLink item={link} />
		</Inline>
	) || null
}
const renderObservation = (obs: Observation, pointType: number, timeframes: DataSetTimeFrame[]) => (
	<>
	  {obs?.Comment && (
		<TextDisplay title="Comment">
		  <b>Comment: </b> {obs.Comment}
		</TextDisplay>
	  )}
	  {obs?.Value && (
		<TextDisplay title="Value">
		<b>Value: </b>
		 {obs.Currency 
		? `${obs.CurrencySymbol}${Intl.NumberFormat('en-US', { 
			minimumFractionDigits: 2, 
			maximumFractionDigits: 2 
			}).format(obs.Value)} ${obs.Currency}`
		: obs.Value
		}
		</TextDisplay>
	  )}
	  {obs?.Percentage && (
		<TextDisplay title="Percentage">
		  <b>Percentage: </b> {obs.Percentage}%
		</TextDisplay>
	  )}
	{obs?.Median && (
	<TextDisplay title="Currency">
		<b>Median: </b>
		{obs.Currency 
		? `${obs.CurrencySymbol}${Intl.NumberFormat('en-US', { 
			minimumFractionDigits: 2, 
			maximumFractionDigits: 2 
			}).format(obs.Median)} ${obs.Currency}`
		: obs.Median
		}
	</TextDisplay>
	)}
	  {obs?.DataWithholdingType?.Label && (
		<TextDisplay title="Data Withholding Type">
		  <b>Data Withholding Type:</b> {obs.DataWithholdingType.Label}
		</TextDisplay>
	  )}
	  {obs?.Percentile10 && (
		<TextDisplay title="Percentile 10">
		  <b>Percentile 10:</b> {obs.Percentile10}
		</TextDisplay>
	  )}
	  {obs?.Percentile25 && (
		<TextDisplay title="Percentile 25">
		  <b>Percentile 25:</b> {obs.Percentile25}
		</TextDisplay>
	  )}
	  {obs?.Percentile75 && (
		<TextDisplay title="Percentile 75">
		  <b>Percentile 75:</b> {obs.Percentile75}
		</TextDisplay>
	  )}
	  {obs?.Percentile90 && (
		<TextDisplay title="Percentile 90">
		  <b>Percentile 90:</b> {obs.Percentile90}
		</TextDisplay>
	  )}
	  {obs?.SizeOfData && (
		<TextDisplay title="Size Of Data">
		  <b>Size Of Data:</b> {obs.SizeOfData}
		</TextDisplay>
	  )}
	  {obs?.SizeOfPopulation && (
		<TextDisplay title="Size Of Population">
		  <b>Size Of Population:</b> {obs.SizeOfPopulation}
		</TextDisplay>)}
	  {obs?.AtPoints && obs.AtPoints.length > 0 && (
		obs.AtPoints.map((point, pointIndex) => {
		  if (point.EntityTypeId !== pointType) {
			const matchingTimeframe = 
			  timeframes?.length > 0 
				? timeframes.find((timeframe) => timeframe.Name === point.Name)
				: null;
	  
			return (
			  <div key={`point-${pointIndex}`}>
				{point.Name && (
				  <TextDisplay>
					<b>{point?.Type}:</b> {point?.Name}
					{matchingTimeframe && (
					  <>
						{matchingTimeframe.AlternateName && (
						  <TextDisplay title="Alternate Name">
							<b>Alternate Name:</b> {matchingTimeframe.AlternateName}
						  </TextDisplay>
						)}
						{matchingTimeframe.Description && (
						  <TextDisplay title="Description">
							<b>Description:</b> {matchingTimeframe.Description}
						  </TextDisplay>
						)}
						{matchingTimeframe.StartDate && (
						  <TextDisplay title="Start Date">
							<b>Start Date:</b> {matchingTimeframe.StartDate}
						  </TextDisplay>
						)}
						{matchingTimeframe.EndDate && (
						  <TextDisplay title="End Date">
							<b>End Date:</b> {matchingTimeframe.EndDate}
						  </TextDisplay>
						)}
						{matchingTimeframe.TimeIntervalDisplay && (
						  <TextDisplay title="Time Interval">
							<b>Time Interval:</b> {matchingTimeframe.TimeIntervalDisplay}
						  </TextDisplay>
						)}
					  </>
					)}
				  </TextDisplay>
				)}
			  </div>
			);
		  }
		  return null;
		})
	  )}
	  
	</>
  );
  
  
const TextDisplay = styled.div`
padding: 0 10px;
font-size: 90%;
`;
const styles: { [key: string]: React.CSSProperties } = {
	tableContainer: {
		borderCollapse: 'collapse',
	},
	headerCell: {
		border: '0.5px solid #ddd',
		padding: '4px',
		textAlign: 'left',
		verticalAlign: 'top',
		backgroundColor: '#DCFAF9',
		width: '550px',
		fontWeight: 'normal',

	},
	dataCell: {
		border: '1px solid #ddd',
		padding: '4px',
		textAlign: 'left',
		verticalAlign: 'top',
		width: '550px',
	},
	cell: {
		border: '1px solid #ddd', 
		padding: '4px',
		verticalAlign: 'top',
		textAlign: 'left',
	},dropdown: {
		width: '50%',
		border: '1px solid #ddd',
		padding: '4px',
		textAlign: 'left',
		backgroundColor: 'white',
		fontSize: 'inherit',
		fontWeight: 'normal',
		borderRadius:'8px'
	  }
};
//
