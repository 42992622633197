import React from 'react';
import styled from 'styled-components/macro';
import { MainSearchType } from '../../types/internal';

import organizationIcon from '../../assets/images/icons/icon-organization-blue-green-01.svg';
import credentialIcon from '../../assets/images/icons/icon-credentials-blue-green-01.svg';
import assessmentIcon from '../../assets/images/icons/icon-assessments-blue-green-01.svg';
import learningOpportunityIcon from '../../assets/images/UpdatedIcons/CE-Icon_3-Learning-Opportunity_Blue-Green_High-Res.png';
import competencyFrameworkIcon from '../../assets/images/icons/icon-competency-framework-white-green-01.svg';
import pathwayIcon from '../../assets/images/icons/icon-pathways-blue-green-01.svg';
import pathwaySetIcon from '../../assets/images/icons/icon-pathwaysets-blue-green-01.svg';
import transerValueIcon from '../../assets/images/icons/icon-transfer-value-blue-green-01.svg';
import transerIntermediaryIcon from '../../assets/images/UpdatedIcons/CE-Icon_17-Transfer-Intermediary_Blue-Green_High-Res.png';
import qaActionIcon from '../../assets/images/UpdatedIcons/CE-Icon_20-QA-Actions_Blue-Green_High-Res.png';
import rubricIcon from '../../assets/images/UpdatedIcons/CE-Icon_21-Rubrics_Blue-Green_High-Res.png';
import metricIcon from  '../../assets/images/UpdatedIcons/CE-Icon_22-Metrics_Blue-Green_High-Res.png';
import industryIcon from '../../assets/images/UpdatedIcons/CE-Icon_12-Industries_Blue-Green_High-Res.png';
import collectionIcon from '../../assets/images/UpdatedIcons/CE-Icon_6-Collection_Blue-Green_High-Res.png';
import conceptSchemeIcon from '../../assets/images/UpdatedIcons/CE-Icon_18-Concept-Scheme_Blue-Green_High-Res.png';
import outcomeDataIcon from '../../assets/images/UpdatedIcons/CE-Icon_8-Outcome-Data_Blue-Green_High-Res.png';
import supportServicesIcon from '../../assets/images/UpdatedIcons/CE-Icon_10-Support_Blue-Green_High-Res.png';
import occupationIcon from '../../assets/images/UpdatedIcons/CE-Icon_11-Occupations_Blue-Green_High-Res.png';
import jobIcon from '../../assets/images/UpdatedIcons/CE-Icon_13-Jobs_Blue-Green_High-Res.png';
import workRoleIcon from '../../assets/images/UpdatedIcons/CE-Icon_14-Work-Roles_Blue-Green_High-Res.png';
import taskIcon from '../../assets/images/UpdatedIcons/CE-Icon_15-Tasks_Blue-Green_High-Res.png';
import progressionModelIcon from  '../../assets/images/UpdatedIcons/CE-Icon_19-Progression-Model_Blue-Green_High-Res.png';
import scheduledOfferingIcon from '../../assets/images/UpdatedIcons/CE-Icon_9-Scheduled-Offering_Blue-Green_High-Res.png';

const Wrapper = styled.div`
    text-align: center;
    padding: 40px;
`;

const Icon = styled.img`
    height: 40px;
`;

interface Props {
    type: string;
}

export default function EmptyResult(props: Props) {
    let icon = organizationIcon;
    switch (props.type) {
        case 'assessment':
            icon = assessmentIcon;
            break;
        case 'competencyframework':
            icon = competencyFrameworkIcon;
            break;
        case 'collection':
            icon = collectionIcon;
            break;
        case 'credential':
            icon = credentialIcon;
            break;
        case 'pathway':
            icon = pathwayIcon;
            break;
        case 'pathwayset':
            icon = pathwaySetIcon;
            break;
        case 'learningopportunity':
            icon = learningOpportunityIcon;
            break;
        case 'scheduledoffering':
            icon = scheduledOfferingIcon;
            break;
        case 'supportservice':
            icon = supportServicesIcon;
            break;
        case 'transfervalue':
            icon = transerValueIcon;
            break;
        case 'transferintermediary':
            icon = transerIntermediaryIcon;
            break;
        case 'conceptscheme':
            icon = conceptSchemeIcon;
            break;
        case 'occupation':
            icon = occupationIcon;
            break;
        case 'job':
            icon = jobIcon;
            break;
        case 'progressionmodel':
            icon = progressionModelIcon;
            break;
        case 'task':
            icon = taskIcon;
            break;
		case 'workrole':
            icon = workRoleIcon;
            break;
        case 'datasetprofile':
        case 'outcomedata':
            icon = outcomeDataIcon;
            break;
        case 'action':
        case 'credentialingaction':
        case 'accreditaction':
            icon = qaActionIcon;
            break;
        case 'rubric':
        case 'rubrics':
            icon = rubricIcon;
            break;
        case 'metric':
        case 'metrics':
            icon = metricIcon;
            break;
        case 'industry':
        case 'industries':
            icon = industryIcon;
            break;
        default:
            break;
    }
    return (
        <Wrapper>
            <Icon src={icon} />
            <p>Sorry, no results were found, try another search</p>
        </Wrapper>
    );
}
