import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { apiUrl } from '../../shared/globals';
import {
    ITagItem,
    ITagSet,
    MainSearchType,
} from '../../types/internal';
import { Filter, FilterItem } from '../../types/external';
import Pill from '../Pill';
import { HSection } from './styles';
import { useHistory } from 'react-router-dom';
import ActivityIndicator from '../ActivityIndicator';
//import { convertFilterItemToIFilterItem } from '../../types/converters';
import { FilterContext } from '../../App';
import { decode } from 'html-entities';
import PillLink from '../PillLink';
import { widgetReplacePluralLabelInText, widgetReplaceSingleLabelInText } from '../../utils/SiteWidgetizer';

interface Props {
    tagSets: ITagSet[];
    onUpdateFilter: (filter: Filter, filterItem: FilterItem) => void;
    searchType: string;
	indentifier?: string | number; // this is for React]
	detailPageLink?: string;
}
export default function SearchResultPillSection({
    tagSets,
    indentifier,
    onUpdateFilter,
	searchType,
	detailPageLink
}: Props) {
    const history = useHistory();
    const filterContext = useContext(FilterContext);
    const [showItems, setShowItems] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tagItems, setTagItems] = useState<ITagItem[]>([]);
	const [selectedSetTagIndex, setSelectedSetTagIndex] = useState(-1);
	const [liveTagData, setLiveTagData] = useState(tagSets);

	async function onTagSetClick(tagSet: ITagSet, setLiveTagData: () => void, index: number) {
        if (index === selectedSetTagIndex) {
            setShowItems(false);
            setSelectedSetTagIndex(-1);
        } else {
            setShowItems(true);
			setSelectedSetTagIndex(index);

			if (tagSet.Values.length === 0 && tagSet.Total && tagSet.Total > 0) {
				setIsLoading(true);
				const url = apiUrl + tagSet.URL;
				let results: any[] = [];
				try {
					const res = await axios.post(url, tagSet.QueryData);
					if (res.data.Successful) {
						results = res.data.Result || [];
					}
				} catch (error) {
					console.log(error);
				}
				tagSet.Values = results;
				setLiveTagData();
				setTagItems(results);
				setIsLoading(false);
			} else {
				setTagItems(tagSet.Values);
			}
        }
    }

	function onTagItemClick(tag: ITagItem, index: number) {
        if (tag.URL) {
            history.push(tag.URL);
        } else {
            let newFilterItem: FilterItem = {
                Label: tag.Label,
                Id: tag.FilterItemID,
                Text: tag.FilterItemText,
                //parentId: tag.FilterItemText ? 'parentId' : undefined,
            } as FilterItem;
			let newFilter: Filter = {
                Id: tag.FilterID
            } as Filter;
            // merge with existing filter
            const filters = filterContext[searchType];
            const filterWithId =
                filters && filters.find((filter) => filter.Id == tag.FilterID);
            if (filterWithId) {
				newFilter.Label=filterWithId.Label;
				newFilter.URI=filterWithId.URI;
                const filterItemWithUrlId = filterWithId.Items.find(
                    (item) => item.Id == tag.FilterItemID,
                );
                if (filterItemWithUrlId) {
                    newFilterItem = {
                        ...newFilterItem,
                        ...filterItemWithUrlId,
                    };
                }
            }
			onUpdateFilter(
				newFilter,
				newFilterItem
            );
        }
    }

    function getFAIcon(iconName: string) {
        return iconName.slice(3);
    }

	var hideCountsFor = ["outcome data", "time estimate"];

    return (
        <div>
            <HSection>
				{liveTagData.map((tagSet, index) => (
					<Pill
						key={`cr-tagset-${indentifier}-${index}`}
						isActive={selectedSetTagIndex === index}
						leftIcon={
							<FontAwesomeIcon
								icon={[
									'fas',
									getFAIcon(tagSet.Icon || '') as IconName,
								]}
								size="lg"
							/>
						}
						rightIcon={
							<FontAwesomeIcon icon="chevron-right" size="lg" />
						}
						rightIconOnActive={
							<FontAwesomeIcon icon="chevron-down" size="lg" />
						}
						content={(hideCountsFor.includes(tagSet?.Label?.toLowerCase() || "") ? "" : tagSet.Total + " ") + widgetReplaceSingleLabelInText(widgetReplacePluralLabelInText(tagSet.Label))}
						onClick={() => onTagSetClick(tagSet, () => {setLiveTagData(liveTagData)}, index)}
                        pillType="filter-button"
                    />
                ))}
            </HSection>
            {isLoading ? (
                <ActivityIndicator />
            ) : (
                showItems && (
                    <HSection>
                            {tagItems.map((tag, index) => (
								<>
									{tag.URL && <PillLink
										key={`cr-tag-${indentifier}-${index}`}
										href={tag.URL ? tag.URL : ""}
										label={decode(tag.Label)}
										target="detailPage"
										title={"Search for resources with " + decode(tag.Label)}
										onClick={() => { onTagItemClick(tag, index); return false; }}
										rightIcon={
											<FontAwesomeIcon
												icon={['fas', (tag.URL ? 'list-alt' : 'search')]}
												size="lg"
											/>
										}
									/>}
									{!tag.URL && <Pill
										key={`cr-tag-${indentifier}-${index}`}
										rightIcon={
											<FontAwesomeIcon
												icon={['fas', 'search']}
												size="lg"
											/>
										}
										title={"Search for resources with " + decode(tag.Label)}
										content={decode(tag.Label)}
										pillType="filter"
										onClick={() => {onTagItemClick(tag, index); }}
										/>
									}
								</>
						))}
                            {detailPageLink && (tagSets[selectedSetTagIndex].Total || 0) > tagItems.length &&
                               

                                <Pill
								key={`cr-tag-${indentifier}-showmore`}
								rightIcon={
									<FontAwesomeIcon
										icon={['fas', 'angle-double-right']}
										size="lg"
									/>
								}
                                content="Detail"
								pillType="filter-button"
								onClick={() => { detailPageLink && window.open(detailPageLink, "detailPage"); }}
                                />
                             
						}
                    </HSection>
                )
            )}
        </div>
    );
}
